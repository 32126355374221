<template>
  <user-form
    :is-admin="isAdmin"
    v-on:delete-user="onDeleteUser"
    v-on:submit="onSubmit"
    :showDeleteButton="showDeleteButton"
  ></user-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UserMixin } from "@/mixins/user.mixin";
import UserForm from "@/components/forms/UserForm.vue";

export default {
  name: "CommonUser",
  mixins: [UserMixin],
  components: { UserForm },
  computed: {
    ...mapGetters({
      activeUserId: "activeUserId",
      selectedUser: `admin/SELECTED_USER`,
      selectedUserId: `admin/SELECTED_USER_ID`
    }),
    isAdmin() {
      return this.$route.query?.isAdmin
        ? `${this.$route.query.isAdmin}` === "true"
        : false;
    }
  },
  methods: {
    ...mapActions({
      clearSelectedUser: "admin/CLEAR_SELECTED_USER",
      deleteSelectedUser: "admin/DELETE_USER",
      toggleUserActiveStatus: "admin/TOGGLE_USER_ACTIVE_STATUS"
    })
  }
};
</script>
