// import {mapGetters} from "vuex";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import Api from "@/services/api";

export const UserMixin = {
  computed: {
    showDeleteButton() {
      const isActiveUser = this.selectedUserId === this.activeUserId;
      return !isActiveUser;
    },
    submitMessage() {
      const message = this.selectedUserId ? "FORM.UPDATED" : "FORM.SUBMITTED";
      return this.$t(message);
    }
  },
  mounted() {
    let endTitle = this.selectedUserId
      ? "MENU.EDIT"
      : this.isRepresentative
      ? "ROUTES.NEW_REPRESENTATIVE"
      : "ROUTES.NEW_USER";

    let subTitle = this.isRepresentative
      ? "ROUTES.SALES_REPRESENTATIVES"
      : "ROUTES.USERS";

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.CORPORATE") },
      { title: this.$t(subTitle) },
      { title: this.$t(endTitle) }
    ]);
  },
  methods: {
    onSubmit(user) {
      const apiAction = this.selectedUserId ? Api.put : Api.post;

      let endPoint = this.isRepresentative ? "representative" : "user";
      endPoint = this.isAdmin ? "admin" : endPoint;
      endPoint += this.selectedUserId ? "s/" + this.selectedUserId : "s";

      const successTitle = this.$t("FORM.SUCCESS_TITLE");
      const errorTitle = this.$t("FORM.ERROR_TITLE");
      const successMessage = this.$t("ROUTES.USERS") + this.submitMessage;
      const errorMessage = this.$t("FORM.ERROR");

      apiAction(endPoint, user)
        .then(() => {
          this.$root.$bvToast.toast(successMessage, {
            title: successTitle,
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.go(-1);
        })
        .catch(() => {
          this.$root.$bvToast.toast(errorMessage, {
            title: errorTitle,
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    },
    onDeleteUser() {
      const userIdField = this.isRepresentative ? "representativeId" : "userId";

      this.toggleUserActiveStatus({
        [userIdField]: this.selectedUser.id,
        isActive: !this.selectedUser.isActive
      })
        .then(() => {
          this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
            title: this.$t("FORM.SUCCESS_TITLE"),
            variant: "success",
            autoHideDelay: 5000
          });
          this.$router.go(-1);
        })
        .catch(err => {
          const msg = err?.response?.data?.message || this.$t("FORM.ERROR");
          this.$root.$bvToast.toast(msg, {
            title: this.$t("FORM.ERROR_TITLE"),
            variant: "danger",
            autoHideDelay: 5000
          });
        });
    }
  }
};
