<template>
  <div class="container">
    <div class="card card-custom">
      <div class="card-header align-items-center"></div>
      <b-form
        inline
        @submit.stop.prevent="onSubmit"
        novalidate
        class="flex-column align-items-stretch"
      >
        <div class="card-body">
          <div class="b-form-inline-group row">
            <label
              for="input-first-name"
              class="
                col-form-label
                text-right
                col-lg-3 col-sm-12
                align-items-start
                justify-content-end
              "
              >{{ $t("FORM.FIRST_NAME") }}</label
            >
            <div class="col-lg-6 col-md-9 col-sm-12">
              <b-form-input
                id="input-first-name"
                name="input-first-name"
                v-model="$v.form.firstName.$model"
                :state="validateState('firstName')"
                aria-describedby="input-first-name-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-first-name-live-feedback">{{
                $t("AUTH.VALIDATION.REQUIRED_FIELD")
              }}</b-form-invalid-feedback>
            </div>
          </div>
          <div class="b-form-inline-group row">
            <label
              for="input-last-name"
              class="
                col-form-label
                text-right
                col-lg-3 col-sm-12
                align-items-start
                justify-content-end
              "
              >{{ $t("FORM.LAST_NAME") }}</label
            >
            <div class="col-lg-6 col-md-9 col-sm-12">
              <b-form-input
                id="input-last-name"
                name="input-last-name"
                v-model="$v.form.lastName.$model"
                :state="validateState('lastName')"
                aria-describedby="input-last-name-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-last-name-live-feedback">{{
                $t("AUTH.VALIDATION.REQUIRED_FIELD")
              }}</b-form-invalid-feedback>
            </div>
          </div>
          <div class="b-form-inline-group row">
            <label
              for="input-email"
              class="
                col-form-label
                text-right
                col-lg-3 col-sm-12
                align-items-start
                justify-content-end
              "
              >{{ $t("FORM.EMAIL") }}</label
            >
            <div class="col-lg-6 col-md-9 col-sm-12">
              <b-form-input
                id="input-email"
                name="input-email"
                type="email"
                v-model="$v.form.email.$model"
                :state="validateState('email')"
                aria-describedby="email-help-block"
              ></b-form-input>
              <b-form-text v-if="isNew" id="email-help-block">
                {{ $t("COMPANY.MAIL_INFO_MESSAGE") }}
              </b-form-text>
              <b-form-invalid-feedback id="input-email-live-feedback">{{
                $t("AUTH.VALIDATION.INVALID", { name: $t("FORM.EMAIL") })
              }}</b-form-invalid-feedback>
            </div>
          </div>
          <div class="b-form-inline-group row">
            <label
              for="input-phone"
              class="
                col-form-label
                text-right
                col-lg-3 col-sm-12
                align-items-start
                justify-content-end
              "
              >{{ $t("FORM.PHONE") }}</label
            >
            <div class="col-lg-6 col-md-9 col-sm-12">
              <b-form-input
                id="input-phone"
                name="input-phone"
                v-model="$v.form.phone.$model"
                :state="validateState('phone')"
                aria-describedby="input-phone-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="input-phone-live-feedback">{{
                $t("AUTH.VALIDATION.REQUIRED_FIELD")
              }}</b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="d-flex card-footer justify-content-end">
          <b-button type="submit" variant="success">
            {{ buttonTitle }}
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  props: ["isAdmin", "showDeleteButton"],
  data() {
    return {
      user: {},
      form: {
        firstName: null,
        lastName: null,
        email: null,
        phone: null
      },
      isNew: true,
      buttonTitle: null
    };
  },
  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      phone: { required }
    }
  },
  computed: {
    ...mapGetters({
      selectedUser: "user/SELECTED_USER",
      selectedAdmin: "admin/SELECTED_USER"
    })
  },
  mounted() {
    this.user = this.isAdmin ? this.selectedAdmin : this.selectedUser;
    this.setForm();
  },
  methods: {
    setForm() {
      const user = this.user;

      if (user?.id) {
        this.isNew = false;
        this.buttonTitle = this.$t("FORM.UPDATE");
        this.form.firstName = user.firstName;
        this.form.lastName = user.lastName;
        this.form.email = user.email;
        this.form.phone = user.phone;
      } else {
        this.buttonTitle = this.$t("FORM.SUBMIT");
      }
    },
    validateState(title) {
      const { $dirty, $error } = this.$v.form[title];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.isAdmin) {
        this.form = { ...this.form, fixOtp: true };
      }
      this.$emit("submit", this.form);
    }
  }
};
</script>
